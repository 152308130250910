import React, { useState } from "react";
import { Heading, Box, Text, Button, Flex, Checkbox } from "@chakra-ui/react";
import { Trans } from "gatsby-plugin-react-i18next";
import { InputField } from "../../components/inputfield";

const ForSignIn = ({ data }) => {
  const [show, setShow] = useState(true);
  return (
    <Flex mt="6.375rem" mb={{ base: "6" }}>
      <Box maxW="29.5rem">
        <Heading
          as="h1"
          fontSize={{ base: "2xl", sm: "3xl" }}
          fontWeight="900"
          mt="5"
          mb="12px"
        >
            {data.title}
        </Heading>
        {show ? (
          <>
            <Text fontSize="xs" color="grey.100" mt="1rem" mb="1.625rem">
              {data.loginForCustomers.description}
              <br />
              <br /> {data.loginForCustomers.description_1}
            </Text>
            <Text fontSize="xs" as="b">
              {data.loginForCustomers.description_2}
            </Text>
            <InputField mb="1rem" mb="18px" />
            <Checkbox
              size="md"
              display="flex"
              alignItems="center"
              colorScheme="green"
              mb="2.62rem"
            >
              <Box size="xs">
                <Trans>{data.loginForCustomers.thermsConditions}</Trans>
              </Box>
            </Checkbox>
            <Button variant="green" w="full" mb="1.875rem">
              {data.loginForCustomers.buttonText}
            </Button>
            <Text size="sm" display="inline">
              {data.loginForCustomers.asOrganization}՝{" "}
            </Text>
            <Text
              as="Button"
              color="darkGreen"
              size="sm"
              onClick={() => setShow(!show)}
            >
              {data.loginForCustomers.login}
            </Text>
          </>
        ) : (
          <>
            <Text fontSize="xs" color="grey.100" mb="1.25rem">
              {data.loginForOrganizations.title}
              <br />
              <br /> {data.loginForOrganizations.description}
            </Text>
            <InputField label={data.loginForOrganizations.labelFullName} mb="1rem" />
            <InputField label={data.loginForOrganizations.labelEmail} mb="2.376rem" />
            <Button variant="green" w="full" mb="1.875rem">
              {data.loginForOrganizations.buttonText}
            </Button>
            <Text size="sm" display="inline">
              {data.loginForOrganizations.indivitual}՝{" "}
            </Text>
            <Text
              as="Button"
              color="darkGreen"
              size="sm"
              onClick={() => setShow(!show)}
            >
              {data.loginForOrganizations.login}
            </Text>
          </>
        )}
      </Box>
    </Flex>
  );
};
export default ForSignIn;
