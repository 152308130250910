import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicOnlineSurvey = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query usePrismicOnlineSurvey {
       allPrismicOnlinesurvey(filter: {tags: {in: "online_survey"}}) {
        nodes {
          lang
          data {
            title {
              text
            }
            description {
              text
            }
            description_1 {
              text
            }
            description_2 {
              text
            }
            thermsconditions {
              text
            }
            asorganization
            login
            buttontext
            title1 {
              text
            }
            description1 {
              text
            }
            inputlabelfullname
            inputlabelemail
            login1
            indivitual
            buttontext1
            header
            image {
              url
            }
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicOnlinesurvey.nodes.filter(item => item.lang === newLang)[0].data)
};

const sanitize = (response) => {
    return {
        header: response.header,
        image: response.image.url,
        title: response.title.text,
        loginForCustomers: {
            description: response.description.text,
            description_1: response.description_1.text,
            description_2: response.description_2.text,
            thermsConditions: response.thermsconditions.text,
            asOrganization: response.asorganization,
            login: response.login,
            buttonText: response.buttontext,

        },
        loginForOrganizations: {
            title: response.title1.text,
            description: response.description1.text,
            labelFullName: response.inputlabelfullname,
            labelEmail: response.inputlabelemail,
            login: response.login1,
            indivitual: response.indivitual,
            buttonText: response.buttontext1,
        },
    }
}

export default usePrismicOnlineSurvey;