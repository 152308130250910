import * as React from "react";
import {Container, Box, Image} from "@chakra-ui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import ForSignIn from "../../components/onlineSurvey/ForSignIn";
import usePrismicOnlineSurvey from "../../hooks/usePrismicOnlineSurvey";

const OnlineSurvey = () => {
  const { language } = useTranslation();
  const data = usePrismicOnlineSurvey()
  return (
    <Main>
      
      <Container
        flexDirection={{ base: "column", md: "row" }}
        maxW="7xl"
        p="0"
        display="flex"
        justifyContent={{ md: "space-between" }}
        mr="0"
      >
        <Seo
          title={data.header}
          description={data.header}
          link={`https://www.fsm.am/${language}/Առցանց Հարցում`}
        />
        <Box maxW="45rem" px={{ base: "5" }}>
          <SmallNav>
            {data.header}
          </SmallNav>

          <ForSignIn data={data} />
        </Box>
        <Image
            objectFit="cover"
            width={606}
            height={{base: '760px'}}
            alt="About us"
            src={data.image}
        />
      </Container>
      
    </Main>
  );
};

export default OnlineSurvey;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
